import axios  from "axios";
import {TrapFocus} from "./trapFocus";

export class Modal {
    modalElem;
    modalCloseBtn;
    triggerBtn;

    transitionDuration = 350;

    focusTrapInstance;

    url;

    constructor(triggerBtn, selector, url) {
        this.onModalClose = this.onModalClose.bind(this);
        this.onClickOutside = this.onClickOutside.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.modalElem = document.querySelector(selector);
        this.modalCloseBtn = this.modalElem.querySelector('[data-modal-close]');
        this.triggerBtn = triggerBtn;

        this.url = url;

        this.initTrapFocus();

        this.modalElem.style.transitionDuration = `${this.transitionDuration}ms`;
    }

    initTrapFocus() {
        this.focusTrapInstance = new TrapFocus(this.modalElem);
    }

    open() {
        this.modalElem.classList.add('modal--open');
        setTimeout(() => {
            this.modalElem.classList.add('trigger-transition');
        });
        this.modalBackdropTarget?.classList.add('modal-backdrop-target--active');

        this.modalFirstFocusableElement?.focus();

        this.attachEventListeners();
        this.focusTrapInstance.init();

        if (this.url) {
            const fetchContent = async () => {
                const response = await axios.get(this.url, {
                    headers: {'X-Requested-With': 'XMLHttpRequest'}
                });

                this.modalElem.querySelector('.modal__content').innerHTML = response.data;
            }

            fetchContent();
        }
    }

    close() {
        this.detachEventListeners();

        this.modalElem.classList.remove('trigger-transition');
        setTimeout(() => {
            this.modalElem.classList.remove('modal--open');
        }, this.transitionDuration);

        this.modalBackdropTarget?.classList.remove('modal-backdrop-target--active');
        this.triggerBtn.focus();
    }

    get modalBackdropTarget() {
        return document.querySelector('.modal-backdrop-target');
    }

    get modalFirstFocusableElement() {
        return document.querySelector('[data-modal-first-focus]');
    }

    onModalClose() {
        this.close();
    }

    onClickOutside({target}) {
        if (!this.modalElem.contains(target)) {
            this.onModalClose();
        }
    }

    onKeyDown(e) {
        if (e.key === 'Escape' || e.keyCode === 27) {
            this.close();
        }
    }

    attachEventListeners() {
        document.addEventListener('keydown', this.onKeyDown);
        this.modalCloseBtn?.addEventListener('click', this.onModalClose);
        setTimeout(() => {
            document.addEventListener('click', this.onClickOutside);
        });
    }

    detachEventListeners() {
        document.removeEventListener('click', this.onClickOutside);
        document.removeEventListener('keydown', this.onKeyDown);
        this.modalCloseBtn.removeEventListener('click', this.onModalClose);
    }
}

