const KEYCODE_TAB = 9;

export class TrapFocus {
    element;
    focusableElements;

    constructor(element) {
        this.element = element;

        this.onKeyDown = this.onKeyDown.bind(this);
    }

    init() {
        if (this.focusableElements && this.focusableElements.length) {
            return;
        }
        this.setFocusableElements();
        this.firstFocusableElement = this.focusableElements[0];
        this.lastFocusableElement = this.focusableElements[this.focusableElements.length - 1];

        this.attachEventListener();
    }

    kill() {
        this.detachEventListener();
    }

    setFocusableElements() {
        this.focusableElements = this.element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])');
    }

    attachEventListener() {
        this.element.addEventListener('keydown', this.onKeyDown);
    }

    detachEventListener() {
        this.element.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e) {
        const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) {
            if (document.activeElement.isEqualNode(this.firstFocusableElement)) {
                this.lastFocusableElement.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement.isEqualNode(this.lastFocusableElement)) {
                this.firstFocusableElement.focus();
                e.preventDefault();
            }
        }
    }

}
